import React, { useState, useEffect, useRef } from "react";

import { useSpring, animated, config } from "react-spring";

import $ from 'jquery';

import Pulse from 'react-reveal/Pulse';

function SubSection(props) {



    const audioRef = useRef()
    const volumeRef = useRef();
    const progressRef = useRef();

    const [volumeLevel, setVolumeLevel] = useState({ level: .5 })
    const [songPosition, setSongPosition] = useState(0)
    const songPositionRef = useRef(songPosition);
    const [mute, setMute] = useState("Mute")
    const [play, setPlay] = useState("Play")
    const [hovered, setHovered] = useState(false);
    const [firstClick, setFirstClick] = useState(0);
    //Animation
    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        },
        config: config.molasses,
    })

    function playAudio() {

            setFirstClick((oldClicks)=> oldClicks + 1)
           
            audioRef.current.paused ? (audioRef.current.play()) : (audioRef.current.pause())
            
            
            setPlay(audioRef.current.paused ? ("Play") : ("Pause"))
      
        
    }

    function pauseAudio() {
        audioRef.current.pause();
    }


    function updateProgress() {

        let value = 0;
        while (!audioRef) {

        }
        if (audioRef && audioRef.current.currentTime > 0) {
            value = (audioRef.current.duration) * audioRef.current.currentTime;

            songPositionRef.current = audioRef.current.currentTime / audioRef.current.duration
            setSongPosition(audioRef.current.currentTime / audioRef.current.duration);
        }
        
    }
    useEffect(() => {
        audioRef.current.addEventListener("timeupdate", updateProgress, false);
        return () => {
            audioRef.current.removeEventListener("timeupdate", updateProgress, false);
        }
    }, [props])


    useEffect(() => {
        
        console.log(isMobile)
  
            console.log(firstClick)
            if(isMobile){
                if(firstClick === 1){
                    setPlay("Loading audio...")
                    setTimeout(() => {
                        setPlay("Pause")
                    }, 3500);
                }
                
            }
            
            
      
    }, [firstClick])

    const handleVolumeChange = (e) => {
        const target = e.target;
        let value = target.value;
        const name = target.name;

        if (name === "level") {
            audioRef.current.volume = value;
            setVolumeLevel(() => {
                return {

                    [name]: value
                }
            })
        }

        if (name === "position") {
            audioRef.current.currentTime = value * audioRef.current.duration;
            progressRef.current.value = value;
            //progressRef.current.style.width = value + "%";
            songPositionRef.current = value
            setSongPosition(value)
        }





    }

    function toggleMute() {
        audioRef.current.muted = !audioRef.current.muted;
        setMute(audioRef.current.muted ? ("Unmute") : ("Mute"))
    }

    const handlerIn = () => {

        $(".jumbotron").hide();
        $("#siteHeader").hide();




    }

    const handlerOut = () => {
        $(".jumbotron").show();
        $("#siteHeader").show();



    }
    let isMobile = false;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    const hoverPulseEvent = ()=>{
        setHovered(true)
        setTimeout(() => {
            setHovered(false)
          }, 1000);
    }

    useEffect(() => {
        if(isMobile){
            $("#volume").hide()
        }
       
    }, [])

    //////RANGE INPUT CODE////////

    //////RANGE INPUT CODE////////

    return (
        <Pulse when={hovered}>
            <div>
                <h2 style={{ fontFamily: "Monument-Extended-Bold", color: "black", marginBottom: 30, textShadow: "5px 5px white" }}>
                    music
            </h2>
                <div id="musicCard" className="card" style={{ backgroundColor: "black", width: "67%", padding: 35, boxShadow: "20px 20px white"}} onMouseEnter={hoverPulseEvent} >
                    <button style={{ marginBottom: 50 }} className="as-text" onClick={() => { audioRef.current.pause(); props.handleClick(); }}>next track</button>
                    <img className={isMobile ? ("img-thumbnail") : ("card-img-top")} src={props.track.img} alt="Card image" width="200" height="300" style={{ padding: 0 }} />
                    <div className="card-body">
                        <h4 className="card-title">track title: {props.track.title}</h4>
                        <p className="card-text"></p>
                        <audio ref={audioRef} id="player" src={props.track.trackUrl}></audio>

                        <div id="controls">
                            <button style={{ position: "relative", fontSize: 20, border: 0, display: "inline-block", margin: 5, fontFamily: "Monument-Extended-Bold", color: "white", background: "none" }} onClick={playAudio} className="play">{play}</button>
                            {/*<button style={{ position: "relative", fontSize: 20, border: 0, display: "inline-block", margin: 5, fontFamily: "Monument-Extended-Bold", color: "white", background: "none" }} onClick={pauseAudio} className="pause">Pause</button>*/}
                            <button style={{ position: "relative", fontSize: 20, border: 0, display: "inline-block", margin: 5, fontFamily: "Monument-Extended-Bold", color: "white", background: "none" }} id="mute" onClick={toggleMute}>{mute}</button>

                            <input style={{ position: "relative", display: "block", marginTop: 30, cursor: "pointer" }} className="range blue" ref={volumeRef} id="volume" min="0" max="1" step="0.00001" type="range" onChange={handleVolumeChange} value={volumeLevel.level} name="level" />
                            <input style={{ position: "relative", display: "block", marginTop: 30, cursor: "pointer" }} className="range blue" ref={progressRef} id="progress" min="0" max="1" step="0.00001" type="range" onChange={handleVolumeChange} value={songPositionRef.current} name="position" />
                        </div>
                    </div>

                </div>
            </div>
        </Pulse>


    )
}

export default SubSection