import React, {useState} from "react";

import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/css/bootstrap.css';

const About = ()=>{
    //$("footer").hide()
    //$("footer").css("display", "none")

    const headerHeight = $("#siteHeader").css("height")

    return (
        <main id="aboutBody" style={{marginTop: 70, width: "100%"}}>
        <div className="container">
            <div style={{ background: "none", textAlign: "center"}} className="jumbotron">
            <h1 id="siteJumbotron" style={{ textAlign: "center", marginBottom: 0, textShadow: "5px 5px white"}}>About</h1>
            <p style={{marginTop: 50, fontFamily: "Monument-Extended-Bold", opacity: 0.5, lineHeight: 2.3}}>Mothball LLC is a firm that concerns itself and invests in expressions of art that need a much needed outlet. We focus on taking risks on the projects that inspire us and we feel will inspire others. Stay tuned.</p>
            </div>
        </div>

    </main>
    )
}

export default About