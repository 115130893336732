import React, {useState} from "react";

import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/css/bootstrap.css';

const Product = ()=>{
   //$("footer").hide()
    //$("footer").css("display", "none")

    const headerHeight = $("#siteHeader").css("height")
    return (
        <main id="productBody" style={{marginTop: 70, textAlign: "center", width: "100%"}}>
        <div className="container">
            <div style={{ background: "none", textAlign: "center"}} className="jumbotron">
            <h1 id="siteJumbotron" style={{ textAlign: "center", marginBottom: 0, textShadow: "5px 5px white"}}>Product</h1>
            <p style={{marginTop: 250, fontFamily: "Monument-Extended-Bold", opacity: 0.5}}>Product coming soon</p>
            </div>
        </div>

    </main>
    )
}

export default Product