import React from "react";
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/css/bootstrap.css';

function Footer(){
    return(
    
      <footer id="footer" style={{textAlign: "right", bottom: 0, width: "100%"}} className="footer"><img id="footer-img" src={require('./sources/mothball_logo_alt_2.png')} height="67" width="200" style={{marginTop: "15px", marginRight: 25}}/></footer>
    
    )
}

export default Footer