import React, { useState, useEffect, useRef } from "react";
import Player from "./Player";
import { useSpring, animated, config } from "react-spring";
import $ from 'jquery';
import "hover.css"
import ReactPlayer from "react-player";
import Pulse from 'react-reveal/Pulse';

function Youtube(props) {

    console.log(props)

    const audioRef = useRef()
    const volumeRef = useRef();
    const progressRef = useRef();

    const [volumeLevel, setVolumeLevel] = useState({ level: .5 })
    const [songPosition, setSongPosition] = useState(0)
    const songPositionRef = useRef(songPosition);
    const cardRef = useRef();
    const [hovered, setHovered] = useState(false);
    //Animation
    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        },
        config: config.molasses,
    })


    let isMobile = false;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        isMobile = true;
    }

    //////RANGE INPUT CODE////////

    //////RANGE INPUT CODE////////

    let matchHeight;
    useEffect(() => {
        console.log(`music height ${$("#musicCard").css("height")}`)
        //.current.style.height = $("#musicCard").css("height")
        //cardRef.current.style.width = $("#musicCard").css("width")
    }, [])

    const hoverPulseEvent = ()=>{
        setHovered(true)
        setTimeout(() => {
            setHovered(false)
          }, 1000);
    }

    return (
        <Pulse when={hovered}>
            <div>
                <h2 style={{ fontFamily: "Monument-Extended-Bold", color: "black", marginBottom: 30, textShadow: "5px 5px white" }}>
                    video
        </h2>
                <div ref={cardRef} className="card" style={{ display: "relative", backgroundColor: "black", width: "75%",  height: "100%",textAlign: "center", height: { matchHeight }, boxShadow: "20px 20px white"}} onMouseEnter={hoverPulseEvent}>
                    <button style={{ marginBottom: 50 }} onClick={() => props.handleClick()} className="as-text">next video</button>
                    <ReactPlayer url={props.video.url} width="100%" controls={true} />
                    <div className="card-body">

                        <h4 className="card-title">video title: {props.video.title}</h4>
                        <p className="card-text"></p>
                    </div>

                </div>
            </div>
        </Pulse>


    )
}

export default Youtube