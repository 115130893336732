import React, { useState, useRef } from "react";
import SubSection from "./SubSection"
import Youtube from "./Youtube"
import { useSpring, useTransition, animated, config } from "react-spring";

import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/css/bootstrap.css';



function MainSection() {
    const tracks = [{ id: 0, title: "thanks, kobe", img: require('./sources/kobe.PNG'), trackUrl: require('./sources/ungaRAE_30_145_bpm.mp3')}, { id: 1, title: "peanutbutter_society_12", img: require('./sources/IMG_1241.jpeg.jpg'), trackUrl: require('./sources/peanutbutter_society_12.mp3') }, { id: 2, title: "beh_2", img: require('./sources/IMG_1145.jpg'), trackUrl: require('./sources/beh_2_Master.wav') }]
    const videos = [{ id: 0, title: "week 1", url: "https://www.youtube.com/watch?v=WI1DRJVX_4I" }, { id: 1, title: "week 2", url: "https://www.youtube.com/watch?v=uB_l_nyVfgY" }]
    const [index, setIndex] = useState(0);
    const [videoIndex, setVideoIndex] = useState(0);
    const cardAnimRef = useRef();
    const cardAnimRefVideos = useRef();

    //Animation
    const fade = useSpring({
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        },
        config: config.molasses,
    })

    const transitions = useTransition(index, p => p, {
        from: { transform: 'translate3d(0,-40px,0)' },
        enter: { transform: 'translate3d(0,0px,0)' },
        leave: { transform: 'translate3d(0,-40px,0)' },
    })

    const videoTransitions = useTransition(videoIndex, p => p, {
        from: { transform: 'translate3d(0,-40px,0)' },
        enter: { transform: 'translate3d(0,0px,0)' },
        leave: { transform: 'translate3d(0,-40px,0)' },
    })
    console.log(fade)

    //Handle click
    const handleClick = () => {
        console.log(tracks.length)

        $("#card-animated").fadeTo("slow", 0);
        if (index === tracks.length - 1) {
            setIndex(0)
        }
        else {
            setIndex(oldIndex => oldIndex + 1)
        }
    }

    const handleClickVideos = () => {
        $("#card-animated-videos").fadeTo("slow", 0);
        if (videoIndex === videos.length - 1) {
            setVideoIndex(0)
        }
        else {
            setVideoIndex(oldIndex => oldIndex + 1)
        }
    }

    const handlerIn = () => {
        console.log("hover  in")
        $(".jumbotron").slideUp();
        $("#siteHeader").slideUp();

    }

    const headerHeight = $("#siteHeader").css("height")

    return (
        <main id="mainSite" style={{ width: "100%", marginTop: 70, marginBottom: 50, textAlign: "center"}}>
            <div className="row" style={{width: "100%", marginBottom: 30}}>
                <div className="container">
                    <div style={{ background: "none", textAlign: "center" }} className="jumbotron">
                        <h1 id="siteJumbotron" style={{ textAlign: "center", marginBottom: 0, fontSize: 55, textShadow: "7.5px 7.5px white"}}>Featured</h1>

                    </div>
                </div>
            </div>

            <div className="row" style={{width: "100%"}}>
                <div className="col" style={{ textAlign: "center", padding: 0, marginBottom: 50}}>
                    {transitions.map(({ item, props, key }) => {
                        return (

                            <animated.div ref={cardAnimRef} id="card-animated" key={key} style={props} >
                                <SubSection track={tracks[item]} handleClick={handleClick} />
                            </animated.div>


                        )
                    })}
                </div>
                <div className="col" style={{ textAlign: "center", padding: 0,  marginBottom: 50}}>
                    {videoTransitions.map(({ item, props, key }) => {
                        return (
                            <animated.div ref={cardAnimRefVideos} id="card-animated-videos" key={key} style={props} >
                                <Youtube video={videos[item]} handleClick={handleClickVideos} />
                            </animated.div>
                        )
                    })}
                </div>
            </div>
        </main>


    )
}

export default MainSection