import React, {useState} from "react";
import $ from "jquery"

const Contact = ()=>{
    //$("footer").hide()
    //$("footer").css("display", "none")

    const headerHeight = $("#siteHeader").css("height")

    return (
        <main id="contactBody" style={{ marginTop: 70, width: "100%"}}>
        <div className="container">
            <div style={{  background: "none", textAlign: "center"}} className="jumbotron">
            <h1 id="siteJumbotron" style={{ textAlign: "center", marginBottom: 0, textShadow: "5px 5px white"}}>Contact</h1>
            <p style={{marginTop: 50, fontFamily: "Monument-Extended-Bold", opacity: 0.5}}>Email us at mothballllc@gmail.com for more information.<br/><br/>
            You can reach us at @mothball_llc on Twitter.<br/><br/>
            @mothball.llc on Instagram.</p>
            </div>
        </div>
        
    </main>
    )
}

export default Contact