import React, {useState, useEffect} from "react";
import $ from 'jquery'
import Pulse from 'react-reveal/Pulse';
import {
    Link
} from 'react-router-dom';

function Header() {
    let mothballLogo = "";

    const [headerBackground, setHeaderBackground] = useState("none");
    const [navItemColors, setNavItemColors] = useState("black");
    const [scrollTop, setScrollTop] = useState($(window).scrollTop())
    const [hovered, setHovered] = useState(false);
    const [navItemShadow, setNavItemShadow] = useState("1.5px 1.5px white")

    $(window).scroll(() => {
        setScrollTop((lastScrollTop)=>{
            if((lastScrollTop > $(window).scrollTop()) && ($(window).scrollTop() !== 0)){
                
              

            }
            else if ((lastScrollTop < $(window).scrollTop())){
                
                
            }
            return $(window).scrollTop()
        })
        if ($(window).scrollTop() <= 5) {
            console.log($(window).scrollTop())
            setHeaderBackground("none")
            setNavItemColors("black")
            setNavItemShadow("1.5px 1.5px white")
        }
        else {
            setHeaderBackground("black")
            setNavItemColors("white")
            setNavItemShadow("1.5px 1.5px black")
        }
    })


    const hideFooter = ()=>{
        console.log("click")
        //$("footer").hide()
        //$("footer").css("display", "none")
    }

    const showFooter = ()=>{
        console.log("click")
        //$("footer").show()
    }

    const hoverPulseEvent = ()=>{
        console.log("WTFFFFF")
        console.log(hovered)
        setHovered(true)
        setTimeout(() => {
            setHovered(false)
          }, 1000);
    }

    return (
       
        <header id="siteHeader" style={{ background: headerBackground, zIndex: 9999, transitionProperty: "background",
            transitionDuration: "0.75s", transitionTimingFunction: "linear"
            }}>
            <nav className="navbar">
                <Link to="/" onClick={showFooter}><div className="navbar-brand"></div></Link>
                <ul id="navItems" className="nav navbar-nav navbar-right">
                    <Pulse when ={hovered}><li style={{ display: "inline" }}  onMouseEnter={hoverPulseEvent} className="nav-item"><Link to="/Product" cursor="pointer" onClick={hideFooter} style={{color: navItemColors, textShadow: navItemShadow}}>Product</Link></li></Pulse>
                    <Pulse when ={hovered}><li style={{ display: "inline"}}  onMouseEnter={hoverPulseEvent} className="nav-item"><Link to="/About" onClick={hideFooter} style={{color: navItemColors, textShadow: navItemShadow}}>About</Link></li></Pulse>
                    <Pulse when ={hovered}><li style={{ display: "inline"}}  onMouseEnter={hoverPulseEvent}  className="nav-item"><Link to="/Contact" onClick={hideFooter} style={{color: navItemColors, textShadow: navItemShadow}}>Contact</Link></li></Pulse>
                </ul>
            </nav>

        </header>
    
    )
}

export default Header