import React , {useState} from 'react';
import logo from './logo.svg';
import Header from "./components/Header";
import MainSection from "./components/MainSection"
import Product from "./components/Product"
import About from "./components/About"
import Contact from "./components/Contact"
import Footer from "./components/Footer"

import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/css/bootstrap.css'

import { BrowserRouter, Route, Switch } from 'react-router-dom';

function App() {
  
const num = 50; //number of pixels before modifying styles




  return (
   <main>
    <Header/>
 
      <Switch>
      
      <Route>
          <Route exact path='/' component={MainSection} />
          <Route exact path='/Product' component={Product} />
          <Route exact path='/About' component={About} />
          <Route exact path='/Contact' component={Contact} />
        </Route>
     
     </Switch>
     <Footer />
    </main>
   
  );
}

export default App;
